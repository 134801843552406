import React, { useState } from "react"
import "./Contact.css"
import emailjs from "emailjs-com"

emailjs.init("user_1V4mAGpGAwYuNX5QSsURL")

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  })
  const { name, email, message } = formData

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const onSubmit = async e => {
    e.preventDefault()
    const mailData = { from_name: name, reply_to: email, message: message }
    setFormData({ name: "", email: "", message: "" })
    await emailjs.send(
      "service_7sevwen",
      "template_gz89fy4",
      mailData,
      "user_1V4mAGpGAwYuNX5QSsURL"
    )
  }

  // const submitThroughEnter = e => {
  //   if (e.key === "Enter" && !e.shiftKey) {
  //     onSubmit(e)
  //   }
  // }

  return (
    <div id="buildWithUs">
      <div className="firstRowWp">
        <h2 className="fs-30 fw-600">Build with us</h2>
        <div className="dividerRed" />
      </div>
      <div className="contactContent">
        <form className="contactForm" onSubmit={e => onSubmit(e)}>
          <p className="block mb-2 fs-12">
            Since we make sure all our products are perfectly tailored to your
            needs we don't offer generic services. Instead, let's talk about the
            product you'd like to build. Either send us a message through the
            contact form or just give us a call.
            <span className="block kizoColor centeredText mt-1">
              +34 666 854 705
            </span>
          </p>
          <input
            className="contactField"
            type="text"
            placeholder="Name"
            name="name"
            value={name}
            onChange={e => onChange(e)}
            required
          />
          <input
            className="contactField"
            type="email"
            placeholder="Email Address"
            name="email"
            value={email}
            onChange={e => onChange(e)}
            required
          />
          <textarea
            className="messageField"
            placeholder="Your message"
            name="message"
            value={message}
            onChange={e => onChange(e)}
            required
          />
          <input type="submit" className="sendMessage" value="Send message" />
        </form>
      </div>
    </div>
  )
}

export default Contact
