import React, { createRef, useState } from "react"
import { gsap } from "gsap"
import { navigate } from "gatsby"
import "./HowPage.css"

const PortfolioItem = ({ item, position }) => {
  const projectRef = createRef()
  const thumbnailRef = createRef()
  const [showHover, setHover] = useState(true)

  const outerHeight = el => {
    var height = el.offsetHeight
    var style = getComputedStyle(el)

    height += parseInt(style.marginTop) + parseInt(style.marginBottom)
    return height
  }

  const handleClick = (projectRef, transitionEl) => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      navigate(`/portfolio/${item.slug}`)
    } else {
      setHover(false)

      const tl = gsap.timeline()

      tl.add(
        gsap.set(transitionEl, {
          position: "fixed",
          top: projectRef.getBoundingClientRect().top + "px",
          left: projectRef.getBoundingClientRect().left + "px",
          height: outerHeight(transitionEl) + "px",
          width: transitionEl.offsetWidth + "px",
          zIndex: 999
        })
      ).add(
        gsap.to(transitionEl, 1, {
          left: 0,
          top: 0,
          width: "100vw",
          height: "100vh",
          transformOrigin: "center center",
          ease: "Power2.easeIn"
        })
      )
      setTimeout(() => navigate(`/portfolio/${item.slug}`), 1000)
    }
  }

  var positionClass = ""
  if (position === 0) {
    positionClass = "topLeft"
  } else if (position === 1) {
    positionClass = "bottomLeft"
  } else if (position === 2) {
    positionClass = "topRight"
  } else if (position === 3) {
    positionClass = "bottomRight"
  }

  return (
    <div
      role="button"
      tabIndex={0}
      className={"portfolioItem " + positionClass}
      key={item.id}
      onClick={() => handleClick(projectRef.current, thumbnailRef.current)}
      onKeyDown={() => handleClick(projectRef.current, thumbnailRef.current)}
      ref={projectRef}
    >
      <img
        className={"projectThumbnail " + positionClass}
        src={item.featured_media.source_url}
        alt="project thumbnail"
        ref={thumbnailRef}
      />
      {showHover ? (
        <div className={"hoverContent " + positionClass}>
          <h1 className="portfolioTitle">{item.title}</h1>
          <div
            className="portfolioExcerpt"
            dangerouslySetInnerHTML={{ __html: item.excerpt }}
          />
        </div>
      ) : null}
    </div>
  )
}

export default PortfolioItem
