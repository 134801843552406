import React, { useState, useEffect } from "react"
import "./LandingPage.css"
import Video from "./../../images/bgMovieSmall.mp4"

const textArray = [
  "Websites",
  "Websites_",
  "Websites",
  "Data Products",
  "Data Products_",
  "Data Products",
  "Apps",
  "Apps_",
  "Apps",
  "Visualizations",
  "Visualizations_",
  "Visualizations"
]

const LandingPage = () => {
  const [textIdx, setTextIdx] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => setTextIdx(textIdx + 1), 1000)
    return () => {
      clearInterval(interval)
    }
  }, [textIdx])

  let currentText = textArray[textIdx % textArray.length]
  return (
    <div className="landingPage">
      <video autoPlay loop muted id="backgroundVideo">
        <source src={Video} type="video/mp4" />
      </video>
      <h1 className="strapline">
        We build
        <span className="block product">{currentText}</span>
      </h1>
    </div>
  )
}

export default LandingPage
