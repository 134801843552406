import React from "react"
import { Helmet } from "react-helmet"
import Header from "./layout/Header"
import LandingPage from "./LandingPage/LandingPage"
import WhatPage from "./WhatPage/WhatPage"
import HowPage from "./HowPage/HowPage"
import WhyPage from "./WhyPage/WhyPage"
import Contact from "./Contact/Contact"
import Footer from "./layout/Footer"
import "./layout.css"

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>KIZO Agency</title>
        <link rel="canonical" href="https://kizo-agency.com" />
      </Helmet>
      <Header />
      <LandingPage />
      <WhatPage />
      <HowPage />
      <WhyPage />
      <Contact />
      <Footer />
    </>
  )
}

export default Layout
