import React from "react"
import { Link } from "gatsby"
import "./WhatPage.css"

const WhatPage = props => {
  return (
    <div className="whatPage" id="what">
      <div className="firstRowWp">
        <h2 className="fs-30 fw-600">What we build</h2>
        <div className="dividerRed"></div>
      </div>
      <div className="wpContent">
        <div className="contentLeft">
          <h2 className="fs-30 fw-600">Data Science</h2>
          <div className="dividerBlack"></div>
          <p className="whatContent">
            Making uninformed decisions is costly.
            <br /> Today many companies sit on unused data that could
            potentially transform their business model. <br />
            We offer end-to-end solutions that help in making data-driven
            decisions. Whether you want automated daily reports on key metrics,
            state of the art Machine Learning techniques predicting your
            Customer Lifetime Value or a centralised database tieing together
            all the data created by your business, we can build it for you.{" "}
            <br />
            What gets measured, gets done.
          </p>
          <Link to="/#buildWithUs">
            <button className="actionButton">Build with us</button>
          </Link>
        </div>
        <div className="contentRight">
          <h2 className="fs-30 fw-600">Web Development</h2>
          <div className="dividerBlack"></div>
          <p className="whatContent">
            We build beautiful, fast and secure web applications that make your
            business grow.
            <br /> Working together with chosen UI/UX experts, our design is
            user-driven while having your business objective in mind. You will
            have easy access to all the content on your website and have free
            reign over which CMS you want your website to connect to. All the
            websites we build are fully responsive and search-engine-optimized.
          </p>
          <Link to="/#buildWithUs">
            <button className="actionButton">Develop with us</button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default WhatPage
