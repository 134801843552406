import React from "react"
import "./WhyPage.css"
import { Link } from "gatsby"
import Logo from "./../../images/logo.gif"

const WhyPage = props => {
  return (
    <div className="whyPage" id="why">
      <div className="firstRowWp">
        <h2 className="fs-30 fw-600">Why we build</h2>
        <div className="dividerRed"></div>
      </div>
      <div className="whyContent">
        <img src={Logo} className="logoGif" alt="logo" />
        <p className="whyMission">
          chtízo (kizo) means building. That’s what we strive for - that’s what
          makes us propel. We create new, relevant, value-adding products,
          platforms and concepts for our clients. Our mission: we want to build
          things that <span className="fw-600">actually</span> matter.
        </p>
        <Link to="/#buildWithUs">
          <button className="buildButton">Build with us</button>
        </Link>
      </div>
    </div>
  )
}

export default WhyPage
