import React from "react"
import PortfolioItem from "./PortfolioItem"
import "./HowPage.css"
import { useStaticQuery, graphql } from "gatsby"

const HowPage = props => {
  const portfolioItems = useStaticQuery(graphql`
    {
      allWordpressWpPortfolio {
        edges {
          node {
            title
            featured_media {
              source_url
            }
            excerpt
            content
            id
            slug
          }
        }
      }
    }
  `).allWordpressWpPortfolio.edges

  return (
    <div className="howPage" id="how">
      <div className="firstRowWp">
        <h2 className="fs-30 fw-600">How we build</h2>
        <div className="dividerRed"></div>
      </div>
      <div className="portfolioGrid">
        <div className="portfolioCol">
          {portfolioItems.slice(0, 2).map((item, index) => (
            <PortfolioItem item={item["node"]} key={index} position={index} />
          ))}
        </div>
        <div className="portfolioCol">
          {portfolioItems.slice(2, 4).map((item, index) => (
            <PortfolioItem
              item={item["node"]}
              key={index}
              position={2 + index}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default HowPage
